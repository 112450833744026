import React from 'react';
import NextImage from 'next/image';
import styles from './Image.module.scss';

const Image = ({
    sizes = '',
    image = {
        url: '',
        focal: { x: '', y: '' },
        title: '',
    },
    priority = false,
    quality = 75,
    objectFit = 'cover',
    loading = 'lazy',
}) => {
    const { url, focal, title } = image;
    return process.env.GENERATE_STATIC_PAGE ? (
        <img
            className={styles['Image--Static']}
            style={{
                objectFit: objectFit,
                objectPosition: `${focal.x} ${focal.y}`,
            }}
            src={url}
            alt={title}
        />
    ) : (
        <NextImage
            className={styles['Image']}
            style={{
                objectFit: objectFit,
                objectPosition: `${focal.x} ${focal.y}`,
            }}
            src={url}
            loading={loading}
            alt={title}
            priority={priority}
            quality={quality}
            sizes={sizes}
            fill
        />
    );
};

Image.propTypes = {};

export default Image;
